<template>
  <sign-page
    title-text="价格体系"
    :request="request"
    :column-list="columnList"
    table-size="small"
    :table-actions="tableActions"
    :form-parms-add="formParmsAdd"
    @tableAction="tableAction"
    :form-parms-update="formParmsUpdate"
    :table-actions-fixed="true"
    :dealFormData="dealFormData"
    :need-data-file="true">
    <fm-modal :mask-closable="false" v-model="modal" width="1000px" v-if="modal" title="明细">
      <div class="table-c" style="height:60vh;width:100%;">
        <sign-page
          :title-text="chooseData.name"
          :initAddFormData="initAddFormData"
          :request="detailRequest"
          :column-list="columnListDetail"
          :form-parms-add="formParmsDetail"
          :form-parms-update="formParmsDetail">
        </sign-page>
      </div>
    </fm-modal>
  </sign-page>
</template>

<script>
import {
  mainbodyMedicineGroupRequest as request,
  mainbodyMedicineRequest
} from '@/api'

export default {
  async created() {
    await this.$store.dispatch('loadMainbodyList')
    this.loadBaseData()
  },
  methods: {
    tableAction (parm, vm) {
      this.pageVm = vm
      this.chooseData = parm.data
      this.modal = true
    },
    dealFormData (formData) {
      formData.useMainbodyIds = formData.useMainbodyIdList.length > 0 ? formData.useMainbodyIdList.join(',') : null
      return formData
    },
    initAddFormData () {
      return {
        mainbodyMedicineGroupId: this.chooseData.id
      }
    },
    async loadDetail (parm) {
      parm.mainbodyMedicineGroupId = this.chooseData.id
      return await mainbodyMedicineRequest.get(parm)
    },
    async loadData (parm) {
      let datas = await request.get(parm)
      let mainbodyLabeMap = {}
      this.$store.getters.mainbodyList.forEach(v => {
        mainbodyLabeMap[v.key] = v.label
      })
      datas.forEach(v => {
        v.useMainbodyIdList = v.useMainbodyIds ? v.useMainbodyIds.split(',').map(v1 => Number(v1)) : []
        v.useMainbodyLabels = v.useMainbodyIdList.map(v1 => mainbodyLabeMap[v1]).join(',')
      })
      return datas
    },
    async loadBaseData () {
      this.baseData = await mainbodyMedicineRequest.getBase()
    }
  },
  computed: {
    tableActions () {
      let data = [
      {
        key: 'detail',
        label: '明细'
      },
      {
        key: 'edit',
        label: '修改'
      },
      {
        key: 'del',
        label: '删除'
      }]
      return data
    },
    formParmsAdd: {
      get () {
        let data = [{
          type: 'input',
          label: '名称',
          key: 'name'
        },
        {
          type: 'select',
          label: '类型',
          key: 'type',
          selectDatas: [{key: 'common', label: '采购'}, {key: 'share', label: '共享药房'}],
          check: {
            required: true
          }
        },
        {
          type: 'multipleChoose',
          label: '适用诊所',
          key: 'useMainbodyIdList',
          selectDatas: this.$store.getters.mainbodyList.filter(v => v.data.dataType === 'clinic')
        },
        {
          type: 'input',
          label: '备注',
          key: 'description'
        }]
        return data
      }
    },
    formParmsDetail: {
      get () {
        let data = [{
          type: 'select',
          label: '药品',
          key: 'medicineId',
          selectDatas: this.baseData.map((v) => {
            return {
              key: v.medicineId,
              label: v.medicineName
            }
          })
        },
        {
          type: 'input',
          label: '售价',
          key: 'price'
        }]
        return data
      }
    },
    formParmsUpdate: {
      get () {
        let data = []
        this.formParmsAdd.forEach((item) => {
          let newItem = {}
          for (let key in item) {
            newItem[key] = item[key]
          }
          data.push(newItem)
        })
        return data
      }
    },
    columnList: {
      get () {
        let data = [{
          title: '名称',
          field: 'name',
          sort: true
        },
        {
          title: '类型',
          field: 'type',
          sort: true,
          render (h, row) {
            const p = [{key: 'common', label: '采购'}, {key: 'share', label: '共享药房'}].find(v => v.key === row.type)
            return h('div', p ? p.label : '-')
          }
        },
        {
          title: '适用诊所',
          field: 'useMainbodyLabels',
          sort: true
        },
        {
          title: '备注',
          field: 'description',
          sort: true
        }]
        return data
      }
    },
    columnListDetail: {
      get () {
        let data = [{ title: '药品名称', field: 'medicineName', fixed: 'left' },
        { title: '药品类型', field: 'medicineType', fixed: 'left' },
        { title: '生产厂家', field: 'medicineManufacturer', fixed: 'left' },
        { title: '药品规格/规格', field: 'medicinePacking', fixed: 'left', width: 130 },
        { title: '售价', field: 'price' }]
        return data
      }
    }
  },
  data () {
    return {
      data: [],
      request: Object.assign({}, request, {
        get: this.loadData
      }),
      pageVm: null,
      baseData: [],
      chooseData: {},
      modal: false,
      detailRequest: Object.assign({}, mainbodyMedicineRequest, {get: this.loadDetail}) 
    }
  }
}
</script>